$(document).on('click', '#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem', ViewLinePanel);
$(document).on('click', '#SuperHeaderContainer #MenuSuperContainer #MobilMenuButtonIcon', OpenMobileMenu);
$(document).on('click', '#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList #CloseMobileMenu', CloseMobileMenu);
var PrincipalMenuTimeOut = null;
$(document).ready(function(){
	$('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem').hover(ShowCategoriesLinesProducts, HideCategoriesLinesProducts);
    $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList').hover(KeepCategoriesContainerHover, LeaveCategoriesContainerHover);
    $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemCategoriesList .SuperMenuLinesListItemCategoriesListItem .SuperMenuLinesListItemCategoriesListItemProductList .SuperMenuLinesListItemCategoriesListItemProductListItemTitle').click(function(event){
        event.stopPropagation();
    });
    $(window).resize(WindowResize);
    $(window).resize();
});
function OpenMobileMenu(){
    $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList').animate({
        left: "0%",
    }, 500);
}
function CloseMobileMenu(){
    $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList').animate({
        left: "-100%",
    }, 500);
}
function WindowResize(){
    var paragraphs = $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList .SuperMenuLinesListItem .SuperMenuLinesListItemLineParagraph');
    if($(window).width()>700){
        paragraphs.each(function() {
            $(this).html($(this).html().replace(/ /g, '<br />'));
        });
        $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList').removeClass('Scrollable');
        
    }else{
        paragraphs.find('br').replaceWith(' ');
        $('#SuperHeaderContainer #MenuSuperContainer #SuperMenuLinesList').addClass('Scrollable');
    }
}
function ViewLinePanel(event){
    event.preventDefault();
    if($(window).width()>700){
        window.open('/Line/'+$(this).attr('value'), '_blank');
    }else{
        $(this).find('.SuperMenuLinesListItemCategoriesList').slideToggle(200);
    }
}
function ShowCategoriesLinesProducts(event){
    event.preventDefault();
    if($(window).width()>700){
        clearTimeout(PrincipalMenuTimeOut);
        $('.SuperMenuLinesListItemCategoriesList').css('z-index', '99');
        $(this).find('.SuperMenuLinesListItemCategoriesList').css('z-index', '100').fadeIn(50);
    }
}
function HideCategoriesLinesProducts(event){
    event.preventDefault();
    var categoryContainer = $(this);
    PrincipalMenuTimeOut = setTimeout(function(){ 
        categoryContainer.find('.SuperMenuLinesListItemCategoriesList').fadeOut(50);
    }, 3000);
    
}
function KeepCategoriesContainerHover(event){
    event.preventDefault();
    clearTimeout(PrincipalMenuTimeOut);
}
function LeaveCategoriesContainerHover(event){
    event.preventDefault();
    $('.SuperMenuLinesListItemCategoriesList').fadeOut(50);
    //$('.SuperMenuLinesListItemCategoriesList').css('display', 'none');
}